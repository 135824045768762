import React, { FC, useState, useEffect } from "react";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import "./index.scss";

interface childProps {
  changelang: (newlang: string) => void;
  deflang: string;
}
//  选择语言，适配value
const langDes: any = {
  "zh-cn": "简体中文",
  "zh-tw": "繁体中文",
  "en-us": "English",
  // "ko-cn": "한국어",
};

const Nav: FC<childProps> = (props: childProps) => {
  const { changelang, deflang } = props;

  const [des, setdes] = useState("");
  //  初始化当前组件语言
  useEffect(() => {
    let deslang = localStorage.getItem("lang") || deflang;
    setdes(deslang);
  }, [deflang]);
  //  切换点击事件
  const onClick = (key: any) => {
    setdes(key.key);
    changelang(key.key);
  };
  //  切换的组件
  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key="zh-cn">简体中文</Menu.Item>
      <Menu.Item key="zh-tw">繁体中文</Menu.Item>
      <Menu.Item key="en-us">English</Menu.Item>
      {/* <Menu.Item key="ko-cn">한국어</Menu.Item> */}
    </Menu>
  );
  return (
    <>
      <div className="contain">
        <Dropdown overlay={menu}>
          <a
            href="/"
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            {langDes[des]} <DownOutlined />
          </a>
        </Dropdown>
      </div>
    </>
  );
};
export default Nav;
