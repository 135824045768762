import React, { FC, useState, useEffect } from "react";
import axios from "axios";

import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import zhTW from "antd/es/locale/zh_TW";
import enUS from "antd/es/locale/en_US";
// import koCN from "antd/es/locale/ko_KR";

import intl from "react-intl-universal";
// import Downbtn from "./components/Downbtn";
// import DesCard from "./components/DesCard";
// import Explain from "./components/Explain";
import Nav from "./components/Nav";
import "./App.scss";

require("intl/locale-data/jsonp/en.js");
require("intl/locale-data/jsonp/zh.js");
// require("intl/locale-data/jsonp/ko.js");
//  国际化语言包
const locales: any = {
  "en-us": require("./locales/EN-US.json"),
  "zh-cn": require("./locales/ZH-CN.json"),
  "zh-tw": require("./locales/ZH-TW.json"),
  // "ko-cn": require("./locales/KO-CN.json"),
};
//  antd国际化配置
const antdlang: any = {
  "zh-cn": zhCN,
  "zh-tw": zhTW,
  "en-us": enUS,
  // "ko-cn": koCN,
};

interface Data {
  android_url: string;
  downandroid_url: string;
  download_nums: string;
  size: string;
  update_time: string;
  version: string;
  apple_store_account: {
    id: string;
    password: string;
  }
}
interface Res {
  datas: Data;
}

const App: FC = () => {
  //  浏览器默认语言
  const defaultl: string = navigator.language.toLowerCase() || "";
  let defaultLang: string = "";
  // 规范语言
  if (
    //  简体中文
    defaultl === "zh-cn" ||
    defaultl === "zh-chs" ||
    defaultl === "zh-chs-cn" ||
    defaultl === "zh-hans" ||
    defaultl === "zh-hans-cn"
  ) {
    defaultLang = "zh-cn";
  } else if (
    //  繁体中文：台湾、香港、澳门
    defaultl === "zh-tw" ||
    defaultl === "zh-hk" ||
    defaultl === "zh-mo" ||
    defaultl === "zh-cht" ||
    defaultl === "zh-cht-cn" ||
    defaultl === "zh-hant" ||
    defaultl === "zh-hant-cn"
  ) {
    defaultLang = "zh-tw";
    // } else if (defaultl === "ko-cn") {
    //   // 韩文
    //   defaultLang = "ko-cn";
  } else {
    //  英文：其他
    defaultLang = "en-us";
  }

  //设备默认信息
  // const u: string = navigator.userAgent || "";
  // let device: string = "";
  // //  ios
  // if (/(iPhone|iPad|iPod|iOS)/i.test(u)) {
  //   device = "ios";
  //   //  android
  // } else if (/(Android)/i.test(u)) {
  //   device = "android";
  //   //  其他
  // } else {
  //   console.log("Change your device to ios or android！！！");
  // }

  //  state
  const [initDone, setinitDone] = useState(false);
  const [lang, setlang] = useState(localStorage.getItem("lang") || defaultLang);
  const [vInfo, getVinfo] = useState<Data>();

  //  useEffect
  //  设备
  useEffect(() => {
    //  第一次渲染语言的空白
    localStorage.setItem("lang", lang);
    //  获取版本信息接口
    getData();
  }, [lang]);
  //  语言
  useEffect(() => {
    loadLocales();
  });

  //  语言函数
  const loadLocales = () => {
    let currentLocale = intl.determineLocale({
      localStorageLocaleKey: "lang",
    });
    intl
      .init({
        currentLocale,
        locales,
      })
      .then(() => {
        setinitDone(true);
      });
  };
  //  数据接口
  const getData = async () => {
    const res = await axios.get<Res>(
      "https://download.rrmine.com/index.php?act=index&op=index"
    );
    getVinfo(res.data.datas);
  };
  const handleCnt = async () => {
    await axios.post(
      "https://download.rrmine.com/index.php?act=index&op=click"
    );
  };
  //  切换语言
  const changelang = (newlang: string) => {
    if (lang === newlang) return;
    setlang(newlang);
    window.location.reload();
  };
  return (
    <>
      {initDone && (
        <ConfigProvider locale={antdlang[lang]}>
          <div className="headerBox">
            <header className="header">
              <Nav changelang={changelang} deflang={defaultLang}></Nav>
            </header>
          </div>

          <section className="logo">
            <img src="asset/logo.png" alt="logo" />
            {/* <p className="tit">{intl.get("app.app01")}</p>
            <p className="titMid">{intl.get("app.app07")}</p> */}
          </section>
          <div className="content">
            <div className="title">{intl.get("app.app01")}</div>
            <div className="article">
              <div>{intl.get("app.app02")}</div>
              <div>{intl.get("app.app03")}</div>
              <div>{intl.get("app.app04")}</div>
              <div>{intl.get("app.app05")}</div>
            </div>
            <div className="title titleTwo">{intl.get("app.app06")}</div>
          </div>

          <div className="newDown">
            <div className="downLeft">
              <a className="leftDown divBox" href="https://testflight.apple.com/join/2KkOuBKi"
                target="__blank">
                <img src="asset/testFlight.png" alt="" />TestFlight {intl.get("app.app07")}</a>
              <a className="rightDown divBox" href={vInfo?.android_url} onClick={handleCnt}>
                <img src="asset/Android.png" alt="" />Android {intl.get("app.app07")}</a>
            </div>
            <div className="downright">
              <img src="/asset/code.png" alt="" />
            </div>

          </div>
          <div className="bottomImg">
          </div>
          {/* <footer>
            <Downbtn
              // device={device}
              // defaultLang={defaultLang}
              android_url={vInfo?.android_url}
            ></Downbtn>
          </footer> */}
        </ConfigProvider>
      )
      }
    </>
  );
};

export default App;
